import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/cGsTNEN9p80">
        <SEO title="Idols - Crossing Rivers" />
    </Layout>
)

export default SermonPost
